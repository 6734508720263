import React, { FC } from "react";

import { navigate as navigate_ } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";

import { type SerializedStyles, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { navigate } from "../../../hooks/navigation";
import Link from "../../base/atoms/Link";
import { Theme } from "../../Theme";
import { IconPredefined } from "../icons/Icon";
import { PredefinedIconType } from "../icons/Icon/Predefined";

export {
    /** @deprecated Use hooks/navigation instead */
    navigate,
    /** @deprecated Use hooks/navigation instead */
    useHref,
    /** @deprecated Use hooks/navigation instead */
    useSameOriginHref,
    /** @deprecated Use hooks/navigation instead */
    useSameOriginHrefAbs,
} from "../../../hooks/navigation";
export {
    /** @deprecated Use components/base/atoms/Link instead */
    default as Link,
} from "../../base/atoms/Link";
export {
    /** @deprecated Use components/base/atoms/Redirect instead */
    default as Redirect,
} from "../../base/atoms/Redirect";

const StyledLink = styled(Link)`
    display: flex;
    column-gap: 5px;
    align-items: center;
`;

const ButtonLink = styled(StyledLink)`
    width: 100%;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    background-color: transparent;
    border-style: solid;
    border-radius: 7px;

    ${({ theme }) => theme.selectors.hover.css`
        filter: brightness(85%);
    `}
    ${({ theme }) => theme.breakpoints.from.medium.css`
        width: fit-content;
        padding: 10px 50px;
    `}
`;

const StyledButton = styled.button`
    display: flex;
    column-gap: 5px;
    align-items: center;
    background-color: unset;
    border: none;
`;

type CMSLink = {
    href: string;
    text: string;
};

type LinkComponentProps = {
    link: CMSLink;
    linkItemCss?: SerializedStyles;
    // font
    color: keyof Theme["colors"];
    // These can be used together because bold also makes the Icon bold, whereas extraBold doesn't
    bold?: boolean;
    extraBold?: boolean;
    fontSize?: string;
    fSExtraSmall?: string;
    fSSmall?: string;
    /** @deprecated */
    fsMedium?: string;
    fSMedium?: string;
    fSNormal?: string;
    fSLarge?: string;
    fSExtraLarge?: string;
    // deco
    underline?: boolean;
    bottomBorder?: boolean;
    // size
    fullWidth?: boolean;
} & ( // type
    | { noLink?: false; button?: false; goBack?: false }
    | { noLink: true; button?: false; goBack?: false }
    | { noLink?: false; button: true; goBack?: false }
    | { noLink?: false; button?: false; goBack: true }
) & // icon
    (
        | {
              icon?: null;
              leftIcon?: undefined;
              moveIcon?: undefined;
              iconSize?: undefined;
              iSSmall?: undefined;
              iSExtraSmall?: undefined;
          }
        | {
              icon: PredefinedIconType;
              leftIcon?: boolean;
              moveIcon?: boolean;
              iconSize?: string;
              iSSmall?: string;
              iSExtraSmall?: string;
          }
    );

const LinkComponent: FC<LinkComponentProps> = ({
    link,
    /** @deprecated Use `css` instead */
    linkItemCss,
    // type
    noLink = false,
    button = false,
    goBack = false,
    // font
    color = "red",
    bold = true,
    extraBold = false,
    fontSize = "1rem",
    fSExtraSmall = fontSize,
    fSSmall = fontSize,
    /** @deprecated Use `fSMedium` instead */
    fsMedium = fontSize,
    fSMedium = fsMedium,
    fSNormal = fontSize,
    fSLarge = fontSize,
    fSExtraLarge = fontSize,
    // deco
    underline = false,
    bottomBorder = false,
    // icon
    icon,
    leftIcon = false,
    moveIcon = true,
    iconSize = "25px",
    iSSmall = iconSize,
    iSExtraSmall = iconSize,
    // size
    fullWidth = false,
    ...props
}) => {
    const theme = useTheme();
    const location = useLocation();

    const colorCode = theme.colors[color]
        ? theme.colors[color].toString()
        : theme.colors.red.toString();

    const linkCss = css`
        column-gap: 10px;
        font-size: ${fontSize};
        font-weight: ${bold ? 600 : "inherit"};
        color: ${colorCode};

        ${theme.breakpoints.propPerEachFrom("font-size", {
            extraLarge: fSExtraLarge,
            large: fSLarge,
            normal: fSNormal,
            medium: fSMedium,
            small: fSSmall,
            extraSmall: fSExtraSmall,
        })}

        ${fullWidth &&
        css`
            justify-content: space-between;
            width: 100% !important;
        `}

        ${theme.selectors.hover.css`
            ${
                moveIcon &&
                css`
                    > div {
                        ${leftIcon ? "right" : "left"}: 10px;
                    }
                `
            }
        `}
    `;

    const styledLinkCss = css`
        ${linkCss}
        ${linkItemCss && linkItemCss}

        text-decoration: ${underline ? "underline" : "inherit"};

        ${bottomBorder &&
        css`
            border-bottom: 2px solid ${colorCode};
        `}

        ${theme.selectors.hover.css`
            color: ${colorCode};
        `}
    `;

    const buttonLinkCss = css`
        ${linkCss};

        justify-content: center;
        border: 2px solid ${colorCode};

        &:hover {
            color: ${colorCode};
        }
    `;

    const iconStyleRight = css`
        position: relative;
        left: 0;
        order: ${leftIcon ? 1 : 2};
        min-width: ${iconSize};
        transition: all 250ms ease-out;
    `;

    const iconStyleLeft = css`
        position: relative;
        right: 0;
        order: ${leftIcon ? 1 : 2};
        transition: all 250ms ease-out;
    `;

    const linkContentCss = css`
        order: ${leftIcon ? 2 : 1};
    `;

    const boldCss = css`
        font-weight: 900;
    `;

    const handleGoBack = () => {
        if ((location.state as any)?.from) {
            void navigate_(-1);
        } else {
            void navigate(link.href);
        }
    };

    const content = (
        <>
            <span
                css={css`
                    ${linkContentCss}
                    ${extraBold && boldCss}
                `}
            >
                {link.text}
            </span>

            {icon && (
                <IconPredefined<PredefinedIconType>
                    type={icon}
                    color={colorCode}
                    size={iconSize}
                    sSmall={iSSmall}
                    sExtraSmall={iSExtraSmall}
                    css={leftIcon ? iconStyleLeft : iconStyleRight}
                />
            )}
        </>
    );

    if (noLink) {
        const noLinkCss = css`
            display: flex;
            column-gap: 5px;
            align-items: center;
            ${styledLinkCss}

            &:hover {
                cursor: pointer;
            }
        `;
        return (
            <div css={noLinkCss} {...props}>
                {content}
            </div>
        );
    }

    if (button) {
        return (
            <ButtonLink to={link.href} css={buttonLinkCss} {...props}>
                {content}
            </ButtonLink>
        );
    }

    if (goBack) {
        return (
            <StyledButton onClick={handleGoBack} css={styledLinkCss} {...props}>
                <span
                    css={css`
                        ${linkContentCss}
                        ${extraBold && boldCss}
                    `}
                >
                    Zurück
                </span>

                {icon && (
                    <IconPredefined<PredefinedIconType>
                        type={icon}
                        color={colorCode}
                        size={iconSize}
                        sSmall={iSSmall}
                        sExtraSmall={iSExtraSmall}
                        css={leftIcon ? iconStyleLeft : iconStyleRight}
                    />
                )}
            </StyledButton>
        );
    }

    return (
        <StyledLink
            to={link.href}
            css={styledLinkCss}
            state={{ from: location.pathname }}
            {...props}
        >
            {content}
        </StyledLink>
    );
};

export default LinkComponent;
